export enum ePlans {
  EXCLUSIVE = 'exclusive',
  MONTHLY_SCHEDULE = 'monthly_schedule',
  ANNUAL_SCHEDULE = 'annual_schedule',
  MONTHLY_KIDS = 'monthly_kids',
  ANNUAL_KIDS = 'annual_kids'
}

export enum eDefaultPlans {
  MONTHLY_SCHEDULE = 'monthly_schedule',
  ANNUAL_SCHEDULE = 'annual_schedule',
  MONTHLY_KIDS = 'monthly_kids',
  ANNUAL_KIDS = 'annual_kids'
}
